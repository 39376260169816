<template>
  <v-dialog 
    v-model="addEventDialog"
    width="900"
    persistent
  >
    <v-card class="add-event-dialog">
      <div
        class="close-icon"
        @click="closeDialog"
      >
        <fa icon="close" />
      </div>
        
          
      <v-card-text>
        <v-container>
          <h3 style="margin-bottom:15px;">
            Create Event
          </h3>
          <div class="form-card">
            <Form
              ref="form"
              :initial-values="initialData"
              :validation-schema="schema"
              @submit="onSubmit"
            >
              <div class="form-row">
                <div class="form-col-full">
                  <label for="title">Title</label>
                  <Field
                    v-model="title"
                    name="title"
                    type="text"
                    placeholder="Title"
                  />
                  <ErrorMessage name="title" /> 
                  <span />
                </div>
              </div>

              <div class="form-row">
                <div class="form-col-half">
                  <label for="label">Label</label>
                  <Field
                    v-model="label"
                    name="label"
                    type="text"
                    placeholder="Label"
                  />
                  <ErrorMessage name="label" /> 
                </div>
                <div class="form-col-half">
                  <label>Event Type</label> 
                  <div class="radio-group">
                    <div class="radio-input-wrap">
                      <Field
                        id="virtual"
                        v-model="eventType"
                        v-bind="field"
                        type="radio"
                        name="eventType"
                        value="online"
                      />
                      <label for="virtual">Virtual</label>
                    </div>
                    <div class="radio-input-wrap">
                      <Field
                        id="physical"
                        v-model="eventType"
                        v-bind="field"
                        type="radio"
                        name="eventType"
                        value="offline"
                      />
                      <label for="physical">Physical</label>
                    </div>
                  </div>
                  <!-- <Field name="title" type="text"  placeholder="Title" />
                <ErrorMessage name="title" />  -->
                  <ErrorMessage name="eventType" /> 
                </div>
                <div class="form-col-half" />
              </div>


              <div class="form-row">
                <div class="form-col-full">
                  <label for="description">Description</label>
                  <Field
                    v-model="description"
                    name="description"
                    v-bind="field"
                    type="text"
                    as="textarea"
                    placeholder="Description"
                  />
                  <ErrorMessage name="description" /> 
                </div>
              </div>

              <FieldArray
                v-slot="{ fields, push, remove }"
                name="days"
              >
                <div
                  v-for="(field, idx) in fields"
                  :key="field.key"
                  class="form-row"
                >
                  <div class="form-col-half">
                    <label for="day">Day {{ idx + 1 }}</label>
                    <div class="input-group">
                      <Field
                        :id="`day_${idx}`"
                        v-slot="{ field }"
                         
                        :name="`days[${idx}].day`"
                      >
                        <flat-pickr
                          v-bind="field"
                          :config="dateConfig"
                          class="date-form-control"
                          :placeholder="`Day ${idx+1}`"
                          name="day"
                        />
                        <div class="input-group-append">
                          <button
                            type="button"
                            colorpicker="colorpickere"
                            data-toggle
                          >
                            <fa icon="calendar" />
                            <span
                              aria-hidden="true"
                              class="sr-only"
                            >Toggle</span>
                          </button>
                        </div>
                      </Field>
                    </div>
                    <ErrorMessage :name="`days[${idx}].day`" />
                    <!-- <Calendar /> -->
                  </div>

                  <div class="form-col-half">
                    <label for="startTime">Start Time</label>
                    <div class="input-group">
                      <Field
                            
                        :id="`startTime_${idx}`"
                        v-slot="{ field }"
                          
                        :name="`days[${idx}].startTime`"
                      >
                        <flat-pickr
                          v-bind="field"
                          :config="timeConfig"
                          class="date-form-control"
                          placeholder="Start Time"
                          name="startTime"
                        />
                        <div class="input-group-append">
                          <button
                            type="button"
                            title="Toggle"
                            data-toggle
                          >
                            <fa icon="clock" />
                            <span
                              aria-hidden="true"
                              class="sr-only"
                            >Toggle</span>
                          </button>
                        </div>
                      </Field>
                    </div>

                    <ErrorMessage :name="`days[${idx}].startTime`" />
                  </div>


                  <div class="form-col-half">
                    <label for="endTime">End Time</label>
                    <div class="input-group">
                      <Field
                            
                        :id="`endTime_${idx}`"
                        v-slot="{ field }"
                          
                        :name="`days[${idx}].endTime`"
                      >
                        <flat-pickr
                          v-bind="field"
                          :config="timeConfig"
                          class="date-form-control"
                          placeholder="End Time"
                          name="endTime"
                        />
                        <div class="input-group-append">
                          <button
                            type="button"
                            title="Toggle"
                            data-toggle
                          >
                            <fa icon="clock" />
                            <span
                              aria-hidden="true"
                              class="sr-only"
                            >Toggle</span>
                          </button>
                        </div>
                      </Field>
                    </div>
                    <ErrorMessage :name="`days[${idx}].endTime`" /> 
                  </div>


                  <div class="form-col-half">
                    <!-- dfdf -->
                    <label :for="`colorpicker_${idx}`">Colorpicker </label>
                    <Field
                      :id="`colorpicker_${idx}`"
                      v-slot="{ field }"
                      type="text"
                      :name="`days[${idx}].color`"
                    >
                      <Picker
                        v-bind="field"
                        :id="`colorpicker_${idx}`"
                      />
                    </Field>
                    <ErrorMessage :name="`days[${idx}].color`" /> 
                  </div>


                  <div class="form-col-half">
                    <div class="arr-btns-group">
                      <div
                        class="arr-btns"
                        @click="push({'day': '','startTime':'','endTime': '','color': ''} )"
                      >
                        +
                      </div>
                      <div
                        class="arr-btns"
                        @click="remove(idx)"
                      >
                        -
                      </div>
                    </div>
                  </div>
                </div>
              </FieldArray>
            
              <div
                v-if="eventType=='offline'"
                class="form-row"
              >
                <div class="form-col-full">
                  <label for="venue">Venue</label>
                  <Field
                    id="venue"
                    v-model="venue"
                    name="venue"
                    as="select"
                  >
                    <option value="">
                      venue
                    </option>
                    <option
                      v-for="item in venueList"
                      :key="item._id"
                      :value="item._id"
                    >
                      {{ item.name || '' }}
                    </option>
                  </Field>
                  <ErrorMessage name="venue" /> 
                  <span />
                </div>
              </div>

              <div 
                v-if="eventType=='online'"
                class="form-row"
              >
                <div class="form-col-full">
                  <label for="meet_link">Meet Link</label>
                  <Field
                    name="meet_link"
                    v-bind="field"
                    type="text"
                    placeholder="Meet Link"
                  />
                  <ErrorMessage name="meet_link" /> 
                  <span />
                </div>
              </div>

              <div class="form-row">
                <div class="form-col-full">
                  <div class="form-actions">
                    <button
                      type="button"
                      @click="resetForm"
                    >
                      Reset
                    </button>
                    <button type="submit">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup>
import {ref,watch,onMounted } from 'vue'
import { useStore } from 'vuex';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { Form, Field, ErrorMessage,FieldArray} from 'vee-validate';
import {getEventData, getVenueData } from '@/service/helper-service.js';
import { isoDateformat, formatTime} from '@/helper/index.js';
import * as yup from 'yup';
import { ColorPicker } from "vue3-colorpicker";
import Picker from '@/components/colorpicker/Picker.vue';
import axios from 'axios';
import "vue3-colorpicker/style.css";
import {useRouter } from 'vue-router';
import { reactive } from 'vue';
let pureColor = ref("orange");
const eventType = ref("offline");


const store = useStore(); 
const router = useRouter();

const addEventDialog =  ref(false);
const title = ref('');
const label = ref('');
const description = ref('');
const venue = ref('');
const venueList = ref([]);

const initialData = reactive({
	days: [
		{'day': '',
			'startTime':'',
			'endTime': '',
			'color':'#eee'}]
});



watch(() => store.state.event.showAddEventDialog, (newValue) => {
	console.log("newValue", newValue);
	if(newValue){
		addEventDialog.value = true;
	}
});

watch(() => store.state.event.eventId, (newValue) => {
	console.log("newValue", newValue);
	if(newValue!=''){
		getEvent(store.state.event.eventId);
	}

});
  
onMounted(()=>{
	loadVenueList(); 
})

  

const closeDialog = ()=>{
	form.value.resetForm();
 
	addEventDialog.value = false;
	store.state.event.showAddEventDialog = false;
	store.state.event.eventId = '';
}

const form  = ref();
 

const dateConfig = ref({
	wrap: true, // set wrap to true only when using 'input-group'
	altInput: true,
	altFormat: "j-M-Y",
	dateFormat: "Y-m-d",
	disableMobile: true  
});

const getDateConfig = (idx) => {
	return {
		wrap: true,
		altInput: true,
		altFormat: 'j-M-Y',
		dateFormat: 'Y-m-d',
		disableMobile: true,
		// onChange: (selectedDatesValue, dateStr) => {
		//   console.log("dateStr", dateStr);
		// 	// selectedDates.value[idx] = dateStr;
		// 	console.log("selectedDatesValue", selectedDatesValue);
		//   if(dateStr){
		//     initialData.days[idx].day = selectedDatesValue;
		//   }
      
		// },
		onOpen: (selectedDatesValue, dateStr, instance) => {
			
			if (idx > 0) {
				console.log("idx",idx);
				console.log("indd:",initialData.days);
			  const previousDate = initialData.days[idx - 1].day;
				instance.set('minDate', previousDate);
			  console.log(`Previous index date for Day ${idx + 1}:`, previousDate);
			}
		},
	};
};

const timeConfig = ref({
	wrap: true, // set wrap to true only when using 'input-group'
	dateFormat: "H:i",
	noCalendar: true,
	enableTime:true,
	time_24hr: true
  
});


const updateEndTimePicker = async (index) => {
	const startTime = initialData.days.value[index].startTime;

	// Wait for the DOM to update
	await nextTick();

	// Access the flatpickr instance and set the minTime
	days.value[index].endTimePickerInstance.set('minTime', startTime);
};


const schema = yup.object({
	title:yup.string().required('Please enter title'),
	label: yup.string().required('Please enter label'),
	eventType:yup.string(),
	description: yup.string().required('Please enter description'),
	days: yup
		.array()
		.of(
			yup.object().shape({
				day: yup.string().required('Please select day'),
				startTime: yup.string().required('Please select starttime'),
				endTime: yup.string().required('Please select endtime'),
				// color: yup.string().required('Please select color')
			})
		)
		.strict(),
	venue:yup.string().when('eventType',{
		is: (val) => val == 'physical',
		then: (schema) => schema.required('Please enter venue'),
		otherwise: (schema) => schema.notRequired()
	}),
	meet_link:yup.string().when('eventType',{
		is: (val) => val == 'virtual',
		then: (schema) => schema.required('Please enter meet link'),
		otherwise: (schema) => schema.notRequired()
	}),
});





const onSubmit = (data) => {
	// alert(JSON.stringify(data, null, 2));
	console.log(JSON.stringify(data, null, 2));
	console.log("data.days",data.days);

	
	const mappedData = data.days.map((item,index) => {
   
		return {
			date:item.day,
			day: index + 1,
			startTime: new Date(`${item.day} ${item.startTime}`).toISOString(),
			endTime: new Date(`${item.day} ${item.endTime}`).toISOString(),
			color:item.color
		};

	});
	console.log("mappedData",mappedData);
	axios
		.post(`${process.env.VUE_APP_SERVICE_URL}/event`,
			{
				"eventName": data.title,
				"description": data.description,
				"venue": data.venue?data.venue:'',
				"type": data.eventType,
				// "eventColor": "FF6D6D",
				"label": data.label,
				"mapLink": data.meet_link?data.meet_link:'',
				"days": mappedData
			},
			{headers:{
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			if(response.status === 200){
				console.log('response',response?.data?.data);
				closeDialog();
				
				let id = response?.data?.data;
				// router.push({ name: 'schedule', params: { id} });
        
			}
		})
};



const getEvent = async(id)=>{
	pureColor.value = '';
	try {
		const response = await getEventData(`/event/${id}`);
		if(response.status === 200){
			addEventDialog.value = true;
			console.log('GET EVENT response',response?.data?.data)
			let eventData = response?.data?.data?.[0];
			title.value = eventData?.eventName;
			label.value = eventData?.label;
			description.value = eventData?.description;
			eventType.value = eventData?.type;
			venue.value = eventData?.venue;
			pureColor.value = eventData?.eventColor;
			let mappedDays = (eventData?.days ?? []).map(item => ({
				day: isoDateformat(item.date),
				startTime: formatTime(item.startTime),
				endTime: formatTime(item.endTime),
			}));
			console.log('mappedDays',mappedDays);
			initialData.days = [...mappedDays] }
	} catch (error) {
		if (error.response) {
			console.log('GET EVENT',error.response);
		}
	}
}

const loadVenueList = async()=>{
	try {
		const response = await getVenueData(`/venue/?limit=10&offset=0`);
		if(response.status === 200){
			console.log('GET Venue response',response?.data?.data)
			let venueData = response?.data?.data;
			venueList.value =  venueData?.map(item => ({
				_id: item._id,
				name: item.name,
			}));
		}} catch (error) {
		if (error.response) {
			console.log('',error.response);
		}
	}
}


const resetForm = ()=>{
	form.value.resetForm()  
}

</script>

<style scoped>
/* add even dialog form */
.add-event-dialog {position: relative!important;border-radius: 8px!important;}
.add-event-dialog .close-icon{color: gray;width:max-content; position: absolute;right: 20px;top: 14px;cursor: pointer;}
.v-card-text h3{display: flex;align-items: center;}

 /* form design    */

.form-row{display: flex;}
label{text-align: left;color:#0C4069;margin-bottom: 4px;font-weight: 600;}
label span{color:red;}
input[type='text'] + span ,textarea + span, .input-group + span{color:red;text-align: left;font-size: 12px;}
.form-col-half,.form-col-full{display: flex;flex: 1; flex-direction: column;margin-right: 15px;margin-bottom: 12px;}
.form-col-half:last-child,.form-col-full:last-child{margin-right:0px;}
/* .form-col-half{flex-basis: 50%;}
.form-col-full{flex-basis: 100%;} */
.form-col-half input[type='text'],.form-col-full input[type='text'],.form-col-half select,.form-col-full select{border:1px solid #B2B2B2;border-radius: 5px;
    height: 39px;padding: 4px 13px;width: 100%;}
.form-col-half textarea ,.form-col-full textarea{border:1px solid #B2B2B2;border-radius: 5px;padding: 4px 13px;}
 .form-col-half input[type='text']:focus,.form-col-full input[type='text']:focus,.form-col-half select:focus{outline: unset;}   
.form-actions{display: flex;justify-content: flex-end;margin-top: 34px;}
.form-actions button{background-color: #007CBA;
    color: white;
    border-radius: 7px;
    padding: 6px 28px;
    margin-left: 18px;}
.error-msg{text-align: left;
    color: red;
    font-size: 12px;}

/* date picker */
.input-group {display: flex;border:1px solid #B2B2B2;border-radius: 5px;}
/* .input-group input{border:unset;outline: unset;} */
/* .flat-pickr{width:100%!important;}  */
/* .flatpickr-input{width: 30%;background-color: #007CBA;} */
:deep() .form-col-half  .date-form-control{
  width: 100%;height: 37px;padding: 12px;}

 :deep() input.date-form-control {border: unset!important;outline:unset!important;} 
  
  .input-group-append {
  display: flex;
  align-items: center; /* Aligns buttons vertically */
  /* Add additional styles as needed */
}
.input-group-append button{margin-right: 7px;}
.input-group-append .fa-calendar,.input-group-append .fa-clock{color:#B2B2B2;}
.radio-group{display: flex;}
.radio-input-wrap{display: flex;margin-right: 12px;}
.radio-input-wrap input{margin-right: 12px;}
.arr-btns-group{display: flex;
    align-items: self-end;
    height: 100%;}
.arr-btns{background-color: #007CBA;
    color: white;
    width: 39px;
    height: 37px;
    border-radius: 5px;
    margin-bottom: 3px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;}
    select{border: 1px solid #B2B2B2;
    padding: 6px;
    border-radius: 5px;
    cursor: pointer;
    outline: unset;
    color:black;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAHCAYAAAD9NeaIAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA+hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NDkxMSwgMjAxMy8xMC8yOS0xMTo0NzoxNiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgKE1hY2ludG9zaCkiIHhtcDpDcmVhdGVEYXRlPSIyMDE1LTA0LTE3VDE3OjEyOjQyKzAyOjAwIiB4bXA6TW9kaWZ5RGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgeG1wOk1ldGFkYXRhRGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgZGM6Zm9ybWF0PSJpbWFnZS9wbmciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTU4MjBDRURERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTU4MjBDRUVERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2RUVFRDJCNkREQzMxMUU0QTM3QUI4MEQzQjkxMTEyOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFNTgyMENFQ0RGNUIxMUU0QTM3QUI4MEQzQjkxMTEyOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuShL/sAAABeSURBVHjaYszOzjZnYGDYCcT8DMSBv0AcP2XKlKVEqmdgAuKTQOwOxB+JtQCIibYAZgkDkRaRZQGyJYQsItsCdEtwWUSRBdgsQbeIYgtAgAWHOMwiJSBezkAhAAgwAJSTG/DI0S9VAAAAAElFTkSuQmCC);
    background-position: 100% 60%;
    background-repeat: no-repeat;
    background-size: auto 15%;
} 
</style>
