<template>
  <div    
    :id="'card_'+event.id"
    class="event-card resize-card"
    :data-id="event.id"
    :data-duration="event.duration"
    :data-title="event.title"
    :data-item="JSON.stringify(event)"
    :draggable="true"
    :class="[updateBorderRadius(event, track.id)]"
  >
    <div 
      :focusable="false"
      class="ellipsis-icon pointer"
      :data-eEnabledId="event.id+'-'+track.id"
      :class="hideEllipsis(event, track.id)"
      @click="openTrackOption($event)"
    >
      &#8942;
    </div>

    <EventOption
      :class="hideEllipsis(event, track.id)"
      :event="event"
      :track-id="track.id"
    />

    <div
      class="event-details"
      :class="hideEventDetails(event, track.id)"
    >
      <div v-if="event.title.length > 14">
        <v-tooltip
          :text="event.title"
          location="top"
        >
          <template #activator="{ props }">
            <div
              class="topic-title"
              v-bind="props"
            >
              {{ truncateString(event.title, 14) }}
            </div>
          </template>
        </v-tooltip>
      </div>
      <div v-else>
        <div class="topic-title">
          {{ event.title }} 
        </div>
      </div> 
     
    
    
      <div class="time-block">
        <span class="start-time">{{ formatTime(event.start) }}</span> &nbsp; to &nbsp;
        <span class="end-time">{{ formatTime(event.end) }} Dur({{ event.duration }})</span>
      </div>
    </div>   

    <div
      v-if="event.speakerImages"
      class="speakers"
      :class="hideEventDetails(event, track.id)"
    >
      <img
        v-for="(picture,index) in event.speakerImages"
        :key="index"
        :src="picture"
        alt=""
      >
    </div>
    <!-- <div
                class="resize-handle"
                @mousedown="onResizeMouseDown($event, event)"
              />
              <div
                class="extend-event-front" 
                :class="allowExtendFrontDrag(item, event, track.id)"
                :data-extendDate="item.date"
                :data-extendEventId="event.id"
                :data-extendTrackId="track.id"
                :draggable="true"
                @dragstart="extendEventStart($event)"
              />-->
             
    <div
      class="extend-event-back" 
      :data-extendDate="item.date"
      :data-extendEventId="event.id"
      :data-extendTrackId="track.id"
      :draggable="true"
      :data-item="JSON.stringify(item)"
      @dragstart="extendEventForward($event)"
    /> 
    <div
      class="merge-card"
      :class="mergeCard(event, track.id)"
    />
  </div>
</template>    

<script setup>
import { defineProps, inject } from 'vue';
import { formatTime, truncateString} from '@/helper/index.js';
import EventOption from '@/components/event_management/event_scheduler/EventOption.vue';


const allowExtendDrag = inject('allowExtendDrag');
const extendEventStart = inject('extendEventStart');
const enableEventOption = inject('enableEventOption');
defineProps({
	event: {
		type: Object,
		required: true,
		default: () => ({})
	},
	track: {
		type: Object,
		required: true,
		default: () => ({})
	},
	item: {
		type: Object,
		required: true,
		default: () => ({})
	},
});

// const emit = defineEmits(['eventStyles','test'
// // 'onEventDragStart','onEventDragOver','onDrag'
// ]);



// function renderStyles(event) {
// 	console.log('Drag started', event);
// 	eventStyles(event);
// }

function allowExtendDragFn(item, event, trackId) {
	allowExtendDrag(item, event, trackId);
}

function extendEventForward(event) {
	extendEventStart(event);

}

function openTrackOption(event) {
	enableEventOption(event)
}
// function onEventDragStart(event) {
//   console.log('Drag started', event);
//   emit('eventDragged', { id: event.target.dataset.id, event });
// }

function updateBorderRadius(eventData, trackId) {
	let trackIndex = eventData?.tracks.findIndex(tid=> tid === trackId) + 1;
 
	if( trackIndex == 1 && eventData?.tracks.length > 1){
		return 'event-color-bar border-radius-unset-right';
	}
  
	if( eventData?.tracks.length > 1 && trackIndex == eventData?.tracks.length){
		return 'border-radius-unset-left';
	}

	if( trackIndex > 1){
		return 'border-radius-unset';
	}
   
	if( trackIndex == 1){
		return 'event-color-bar';
	}

}


function hideEventDetails(eventData, trackId) {
	let trackIndex = eventData?.tracks.findIndex(tid=> tid === trackId) + 1;

	if( trackIndex > 1){
		return 'd-none';
	}else{
		return '';
	}
}

function hideEllipsis(eventData, trackId) {
	let trackIndex = eventData?.tracks.findIndex(tid=> tid === trackId) + 1;

	if( eventData?.tracks.length > 1 &&  trackIndex != eventData?.tracks.length ){
		return 'd-none';
	}
}

function mergeCard(eventData, trackId) {
	let trackIndex = eventData?.tracks.findIndex(tid=> tid === trackId) + 1;
	if(trackIndex == 1  ){
		return 'd-none';
	}
}



</script>

<style scoped>
.event-card{box-shadow:rgba(0, 0, 0, 0.1) 6px -1px 15px -3px, rgba(0, 0, 0, 0.05) 1px 0px 1px -2px;
    width: 100%;
    height: auto;
    background: white;
    z-index: 1020;
    position: absolute;
    left: 0;
    right: 0;
    border-radius: 12px;
    padding:6px 11px;
    /* overflow: hidden; */
  }
 .event-card::after,.event-color-bar::after{
  position: absolute;
    content: '';
    width: 9px;
    left: 0;
    bottom: 0;
    top: 0;
    border-radius: 12px 0px 0px 12px;
 }   
.border-radius-unset{border-radius:unset;} 
.border-radius-unset-left{border-radius: 0px 12px 12px 0px;}
.border-radius-unset-right{border-radius: 12px 0px 0px 12px;}
.d-none{display: none;}
	.resize-handle {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  cursor: ns-resize;
}
/* .topic-card:hover .extend-event{opacity: 1;} */
.extend-event-front,.extend-event-back{
	/* opacity: 0; */
	position: absolute;
    top: 0;
    bottom: 0;
    width: 10px;
    height: 100%;
    z-index: 1020;
    cursor: col-resize;
}

.merge-card{
  position: absolute;
    top: 0;
    bottom: 0;
    left: -7px;
    background-color: #fff;
    width: 12px;
    height: 100%;
    z-index: 2040;
}
.extend-event-front{left: 0;border-radius: 12px 0 0 12px}
.extend-event-back{right: 0;}
/* .topic-card:nth-child(1){background-color: greenyellow;} */

 .external-event{position: relative;margin-bottom: 12px;}   
.topic-title{font-size: 18px;font-weight: 600;}    
.time-block{display: flex;font-size:14px;color: gray;
  font-weight: 700;}   

  .ellipsis-icon{
    width: 28px;
    height: 28px;
    color: black;
    font-weight: 800;
    font-size: 20px;
    position: absolute;
    right: -8px;
    top: 4px;} 

.speakers{margin-top: -1px;}
.speakers img{    border-radius: 50%;
    margin-right: 4px;
    width: 36px;
    height: 36px;
    object-fit: cover;}
</style>