<template>
  <div
    class="track"
    :data-track-id="track.id"
    :class="'track-'+spliceFirstChar(track.color)"
  >
    <div class="track-label">
      <div v-if="track.name.length > 10">
        <v-tooltip
          :text="track.name"
          location="top"
        >
          <template #activator="{ props }">
            <span v-bind="props">{{ truncateString(track.name, 10) }}</span>
          </template>
        </v-tooltip>
      </div>
      <div v-else>
        {{ track.name }}
      </div> 
      <div 
        class="ellipsis-icon pointer enable-option"
        :data-show-track-id="track.id"
        @click="openTrackOption($event)"
      >
        &nbsp; &#8942;
      </div>
      <TrackOption
        :date="date"
        :track="track"
      />
    </div>
          
    <slot />
  </div>
</template>

<script setup>
import { defineProps, onMounted, inject } from 'vue';
import { formatTime, truncateString} from '@/helper/index.js';
import TrackOption from '@/components/event_management/event_scheduler/TrackOption.vue'

const showTrackOption = inject('showTrackOption');

defineProps({
	track: {
		type: Object,
		required: true,
		default: () => ({})
	},
	date: {
		type: String,
		required: true
	}
});

const spliceFirstChar = (color)=>{
	  return color[0] == '#'?color.slice(1):color;
}

const openTrackOption = (event)=>{
	showTrackOption(event);
}




</script>

<style scoped>
.track-label{text-align: center;
    font-size: 16px;
    margin-top: -5px;
    font-weight: 600;
	font-family: 'Mulish',sans-serif;}
 .track-cell{height: 100%;position: relative;}   

 .ellipsis-icon{width: 28px;
    height: 28px;
    color: black;
    font-weight: 800;
    font-size: 20px;
    position: absolute;
    right: 28px;
    top: -6px;}
 </style>