<template>
  <div
    class="topic-card"
  >
    <div class="actions">
      <div v-if="item.title.length > 15">
        <v-tooltip
          :text="item.title"
          location="top"
        >
          <template #activator="{ props }">
            <h3 v-bind="props">
              {{ truncateString(item.title, 15) }}
            </h3>
          </template>
        </v-tooltip>
      </div>
      <div v-else>
        <h3>{{ item.title }}</h3>
      </div> 
      <div>
        <fa
          icon="pencil"
          class="pointer"
          @click="editTopic"
        />
        <fa
          icon="trash"
          class="pointer"
          @click="deleteTopic"
        />
      </div>
    </div>
    <div class="topic-duration">
      {{ formatDuration(item.duration) }}
    </div>
	
    <div
      v-if="item.speakerImages"
      class="speakers"
    >
      <img
        v-for="(item,index) in item.speakerImages"
        :key="index"
        :src="item"
        alt=""
      >
      <!-- <img
        src="@/assets/images/speaker-1.png"
        alt=""
      > -->
    </div>
  </div>
</template>

<script setup>
import { formatDuration, truncateString } from '@/helper/index.js';
import {ref,toRef, watch, onBeforeMount} from 'vue'
import { useStore} from 'vuex';
import axios from 'axios';

const store = useStore(); 
const emit = defineEmits(['deleteTopic','editTopic']);
const props = defineProps({
	item:{
		type:Object,
		required:true,
		default:() => ({})
	}
})

const deleteTopic = ()=>{
	emit('deleteTopic');
}

const editTopic = ()=>{
	emit('editTopic');
}



const imageList = ref([])

onBeforeMount(()=>{
	let speakerData = props.item?.speakerDetails;
	if(speakerData){
		for(let i = 0;i<speakerData.length;i++){
			console.log(speakerData[i]?.image?.split('/profile/')[1])
			loadImage(props.item?.id, speakerData[i]?.image?.split('/profile/')[1])
		}
	}
})





// const imageUrl = ref(require(`@/assets/images/thumbnail.png`));

function loadImage(id,fileName){
	imageList.value = [];
	if(fileName){
		axios
			.get(`${process.env.VUE_APP_SERVICE_URL}/files?name=${fileName}&type=profile`,
				{headers:{
					'Authorization': `Bearer ${store.state.authentication.token}`,
				}})
			.then((response) => {
				if(response.status === 200){
					//imageList.value.push('data:image/jpeg;base64,'+response?.data?.data);
					let trackIndex = store.state.event.topics?.findIndex(item => item.id === id);
					store.state.event.topics[trackIndex].speakerImages.push('data:image/jpeg;base64,'+response?.data?.data);
					console.log("imageList.value",store.state.event.topics)
				}
			})
			.catch((error)=>{
				if (error.response) {
					if(error.response.data.message){
						if(error.response.data.message ==='jwt expired'){
							store.commit('snackbar/showSnackbar',{
								show:true,
								message:'Token expired',
								color:'danger',
								timeout:2000});
							store.commit('authentication/logout',2000);
						}
						store.commit('snackbar/showSnackbar',{
							show:true,
							message:error.response.data.message,
							color:'danger',
							timeout:2000});

					}else{

						store.commit('snackbar/showSnackbar',{
							show:true,
							message:error.response.statusText,
							color:'danger',
							timeout:2000});
					}
				}else {
					console.log("error.message",error.message)
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:error.message,
						color:'danger',
						timeout:2000});
				}
			});
  
	}else{
		//   albumArray.value.push({'id':id,'albumName':albumName,'coverPicture':require(`@/assets/images/thumbnail.png`)})
	}
  
}

watch(store.state.event.topics, (newValue) => {
	console.log("WATCH speakerDetails",newValue);
	// let speakerData = props.item?.speakerDetails;
	let speakerData = store.state.event.topics.speakerDetails;
	if(speakerData){
		for(let i = 0;i<speakerData.length;i++){
			console.log(speakerData[i]?.image?.split('/profile/')[1])
			loadImage(speakerData[i]?.image?.split('/profile/')[1])
		}
	}
});
</script>

<style scoped>
.topic-card{box-shadow: 0px 5px 10px 0px #93939340;
padding: 12px;
margin: 10px;
    border-radius: 12px;} 
.topic-card h3{font-size: 16px;}
.topic-card .actions{display: flex;
    align-items: center;
    justify-content: space-between;}
 .topic-duration{font-size: 14px;
    margin-top: 6px;
    
}
.fa-pencil{margin-right: 5px;cursor: pointer;}
.fa-trash{color:#EC1B1B;cursor: pointer;}
.speakers{margin-top: 10px;}
.speakers img{    border-radius: 50%;
    margin-right: 12px;
    width: 46px;
    height: 46px;
    object-fit: cover;}
</style>

